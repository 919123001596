.filter-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 25px;
}
.customize-autocomplete{
    width: 200px;
    margin-bottom: 0 !important;
}
.filter-list{
    position: absolute;
    top: 24px;
    background: #FFF;
    z-index: 9999;
    padding: 10px;
    width: 200px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}